<template>
  <page-header :title="t('chargingSessions.title')" />
  <div >
    <ChargingSessions></ChargingSessions>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'; 
import PageHeader from '@/components/common/layout/PageHeader.vue';
import ChargingSessions from '@/components/chargingSessions/ChargingSessions.vue';
 
const { t } = useI18n();

</script>

<style lang="scss" scoped>
.container{
  max-width: max-content;
}
</style>
 