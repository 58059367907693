<template>
  <div v-if="loading" class="loader-wrapper flex">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <div class="container container-grey" style="max-width: max-content">
    <Message
      v-if="notAuthorized"
      data-cy="hardware-systems-unauthorized-message"
      severity="warn"
      icon="pi pi-exclamation-triangle"
      :closable="false"
      >{{ $t('notAuthorized') }}
    </Message>
    <DataTable
      v-if="!loading && !notAuthorized"
      data-cy="charge-park-sessions-table"
      @sort="onSort($event)"
      :value="chargingSessionsStore.chargingSessions"
      :paginator="true"
      :alwaysShowPaginator="false"
      :rows="DEFAULT_CHARGING_SESSIONS_PAGE_SIZE"
      v-model:filters="filters"
      filterDisplay="menu"
      dataKey="id"
      class="p-datatable-sm mb-5 mt-2 border-rounded overflow-hidden max-w-max"
      v-model:selection="selectedSystem"
      selectionMode="single"
      :metaKeySelection="false"
      @row-click="onRowSelect"
      lazy
    >
      <template #header>
        <div class="grid lg:grid-cols-3 grid-cols-1">
          <div class="flex align-items-center p-4 lg:col-span-2 col-span-1">
            <div class="w-full">
              <span class="p-input-icon-left search pr-1">
                <i class="pi pi-search" />
                <!--@vue-ignore-->
                <InputText
                  data-cy="hardware-systems-table-search"
                  v-model="searchInput"
                  @keyup.enter.="filters['global'].value = searchInput"
                  :placeholder="
                    $t('searchBy', {
                      name: `${$t('chargingSessions.sessionID')},${$t(
                        'hardwareSystem.assetName'
                      )}, ${$t('chargingSessions.evseID')}, ...`,
                    })
                  "
                  class="p-inputtext-sm"
                />
              </span>
              <!--@vue-ignore -->
              <Button
                :icon="'pi pi-search'"
                :label="$t('search')"
                @click="filters['global'].value = searchInput"
              />
            </div>
          </div>

          <div class="flex flex-wrap justify-self-end justify-end gap-4 p-4">
            <DateTimeInput
              data-cy="charging-sessions-filter-time-from"
              ref="startDateTimeCalendar"
              class="w-[210px]"
              v-model="fiterStartTime"
              :maxDateTime="maxTimeFrom"
              :stepMinute="10"
              :placeholder="$t('chargePark.timeFrom')"
              @update:modelValue="applyStartDateAndTimeFilter"
            />
            <DateTimeInput
              data-cy="charging-sessions-filter-time-to"
              ref="endDateTimeCalendar"
              class="w-[210px]"
              v-model="filterEndTime"
              :minDateTime="minTimeTo"
              :maxDateTime="maxTimeTo"
              :stepMinute="10"
              :placeholder="$t('chargePark.timeTo')"
              @update:modelValue="applyEndDateAndTimeFilter"
            />
          </div>
        </div>
      </template>
      <template #empty>
        <span data-cy="hardware-systems-table-empty" class="w-full block text-center">{{
          $t('chargingSessions.empty')
        }}</span>
      </template>



      <Column
        v-if="!props.system_id"
        class="no-top-border-row"
        field="system_name"
        :header="$t('hardwareSystem.assetName')"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <span
            v-if="data.system_name"
            data-cy="hardware-system-charge-park"
            :data-cy-system-id="data.system_id"
            @click="openAssetPage($event, data.system_id)"
            >{{ data.system_name }}</span
          >

          <span v-if="!data.charge_park_name" class="empty">--</span>
          <br />

          <span
            v-if="data.charge_park_name"
            :data-cy-system-id="data.system_id"
            data-cy="charging-session-system-organisation"
            class="organisation font-thin text-sm text-gray-400 truncate ..."
            >{{ data.charge_park_name }}</span
          >
          <span v-if="!data.charge_park_name" class="empty text-gray-400">--</span>
        </template>
      </Column>
      <Column
        class="no-top-border-row"
        field="evse_id"
        style="min-width: 8rem"
        :header="$t('chargingSessions.evseID')"
        :filterMatchModeOptions="idMatchModes"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <span v-if="data.session_id" data-cy="charging-evse-id">{{ data.evse_id }}</span>
          <span v-else class="empty">--</span>
        </template>

        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="$t('filterBy') + ' ' + $t('chargingSessions.evseID')"
          />
        </template>
      </Column>
      <Column
        class="no-top-border-row"
        field="session_end"
        :header="$t('time')"
        style="min-width: 16rem"
        :sortable="true"
        :filterMatchModeOptions="rangeMatchModes"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <date-time-display
            v-if="data.session_start"
            :date="data.session_start"
            data-cy="charging-session-start"
          />
          <span v-else class="empty">--</span>
          <br />
          <date-time-display
            v-if="data.session_end"
            :date="data.session_end"
            data-cy="charging-session-end"
          />
          <span v-else class="empty">--</span>
        </template>
      </Column>
      <Column
        class="no-top-border-row"
        field="duration"
        :header="$t('chargingSessions.duration')"
        :showFilterMatchModes="false"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <span v-if="data.session_start && data.session_end" data-cy="charging-duration">{{
            getDurationFromSec(data.duration)
          }}</span>
          <span v-else class="empty">--</span>
        </template>

        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.matchMode"
            class="p-column-filter mb-2"
            optionValue="value"
            optionLabel="label"
            :options="rangeMatchModes"
          />
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="$t('filterBy') + ' ' + $t('seconds')"
          />
        </template>
      </Column>
      <Column
        class="no-top-border-row"
        field="end_soc_percent"
        :header="$t('chargingSessions.soc')"
        :sortable="true"
        :filterMatchModeOptions="rangeMatchModes"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <span v-if="data.start_soc_percent" data-cy="charging-start-soc"
            >{{ data.start_soc_percent.toFixed(2) }}%</span
          >
          <span v-else class="empty">--</span>
          <span> → </span>
          <span v-if="data.end_soc_percent" data-cy="charging-end-soc"
            >{{ data.end_soc_percent.toFixed(2) }}%</span
          >
          <span v-else class="empty">--</span>
        </template>
      </Column>
      <Column
        class="no-top-border-row"
        field="energy_kwh"
        :header="$t('chargingSessions.energyCharged')"
        :sortable="true"
        :showFilterMatchModes="false"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <span
            v-if="data.energy_kwh !== undefined && data.energy_kwh !== null"
            data-cy="charging-energy-charged"
            >{{ data.energy_kwh.toFixed(4) }} kWh</span
          >
          <span v-else class="empty">--</span>
        </template>
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.matchMode"
            class="p-column-filter mb-2"
            optionValue="value"
            optionLabel="label"
            :options="rangeMatchModes"
          />
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="$t('filterBy') + ' ' + $t('chargingSessions.energyCharged')"
          />
        </template>
      </Column>
      <Column
        class="no-top-border-row"
        field="peak_power_kw"
        :header="$t('chargingSessions.peakPower')"
        :sortable="true"
        :showFilterMatchModes="false"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <span
            v-if="data.peak_power_kw !== undefined && data.peak_power_kw !== null"
            data-cy="charging-session-peak-power"
            >{{ data.peak_power_kw.toFixed(0) }} kW</span
          >
          <span v-else class="empty">--</span>
        </template>

        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.matchMode"
            class="p-column-filter mb-2"
            optionValue="value"
            optionLabel="label"
            :options="rangeMatchModes"
          />
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="$t('filterBy') + ' ' + $t('chargingSessions.peakPower')"
          />
        </template>
      </Column>

      <Column
        class="no-top-border-row"
        field="power_lower_than_requested_percent"
        :header="$t('chargingSessions.powerLowerThanRequestetedPercent')"
        :sortable="true"
        :showFilterMatchModes="false"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <span
            v-if="
              data.power_lower_than_requested_percent !== undefined &&
              data.power_lower_than_requested_percent !== null
            "
            data-cy="charging-session-power-lower-than-requested-percent"
            >{{ data.power_lower_than_requested_percent.toFixed(2) }}%</span
          >
          <span v-else class="empty">--</span>
        </template>

        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.matchMode"
            class="p-column-filter mb-2"
            optionValue="value"
            optionLabel="label"
            :options="rangeMatchModes"
          />
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="
              $t('filterBy') + ' ' + $t('chargingSessions.powerLowerThanRequestetedPercent')
            "
          />
        </template>
      </Column>
      <Column class="no-top-border-row" field="reason_session_end" :header="$t('status')">
        <template #body="{ data }: { data: ChargingSession }">
          <span v-if="data.reason_session_end" data-cy="charging-session-reason-end"
            >{{ data.reason_session_end }}
          </span>
          <span v-else class="empty">--</span>
        </template>
      </Column>
      <Column
        class="no-top-border-row"
        field="session_id"
        style="min-width: 21rem"
        :header="$t('chargingSessions.sessionID')"
        filterField="session_id"
        :filterMatchModeOptions="idMatchModes"
      >
        <template #body="{ data }: { data: ChargingSession }">
          <span v-if="data.session_id" data-cy="charging-session-id">{{ data.session_id }}</span>
          <span v-else class="empty">--</span>
        </template>

        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="$t('filterBy') + ' ' + $t('chargingSessions.sessionID')"
          />
        </template>
      </Column>
      <template #footer v-if="pageCount > DEFAULT_CHARGING_SESSIONS_PAGE_SIZE">
        <Paginator
          :rows="DEFAULT_CHARGING_SESSIONS_PAGE_SIZE"
          :totalRecords="pageCount"
          :pageLinkSize="amountOfPages"
          :alwaysShow="false"
          @page="onPageChange"
        ></Paginator>
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import DateTimeInput from '@/components/common/time/DateTimeInput.vue';
import Paginator from 'primevue/paginator';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import { getDurationFromSec } from '@/utils/dateTimeFormat';
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { DEFAULT_CHARGING_SESSIONS_PAGE_SIZE } from '@/utils/constants';
import { isAxiosError } from 'axios';
import DataTable from 'primevue/datatable';

import type {  DataTableSortEvent } from 'primevue/datatable';
import Message from 'primevue/message';

import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';

import { useI18n } from 'vue-i18n';
import type { ChargeParkQueryParams, ChargingSession } from '@/models/chargingSessions.model';
import { FilterMatchMode } from 'primevue/api';
import { useChargingSessionsStore } from '@/stores/admin/chargingSessions/chargingSessions.store';
import type { PageState } from 'primevue/paginator';
import { CHARGING_SESSIONS, HARDWARE_SYSTEMS } from '@/utils/routeNames';
const props = defineProps<{
  charge_park_id?: number;
  system_id?: number;
}>();
const searchInput = ref('');
const { t } = useI18n();
const chargingSessionsStore = useChargingSessionsStore();
const loading = ref(true);
const notAuthorized = ref(false);
const { chargingSessionsMeta } = storeToRefs(chargingSessionsStore);
const rangeMatchModes = [
  { label: 'Greater than', value: FilterMatchMode.GREATER_THAN },
  { label: 'Less than', value: FilterMatchMode.LESS_THAN },
];

const idMatchModes = ref([{ label: 'Contains', value: FilterMatchMode.CONTAINS }]);
const amountOfPages = computed(() => {
  if (chargingSessionsMeta.value.count / DEFAULT_CHARGING_SESSIONS_PAGE_SIZE > 10) {
    return 10;
  } else {
    return Math.ceil(chargingSessionsMeta.value.count / DEFAULT_CHARGING_SESSIONS_PAGE_SIZE) % 1 !==
      0
      ? Math.ceil(chargingSessionsMeta.value.count / DEFAULT_CHARGING_SESSIONS_PAGE_SIZE) + 1
      : Math.ceil(chargingSessionsMeta.value.count / DEFAULT_CHARGING_SESSIONS_PAGE_SIZE);
  }
});
const selectedSystem = ref();
const fiterStartTime = ref();
const filterEndTime = ref();

const router = useRouter();
const maxTimeFrom = computed(() => {
  if (!fiterStartTime.value) return new Date().toISOString();
  return filterEndTime.value;
});
const maxTimeTo = computed(() => {
  return new Date().toISOString();
});
const minTimeTo = computed(() => {
  if (!fiterStartTime.value) return undefined;
  return fiterStartTime.value;
});

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  session_id: { value: null, matchMode: FilterMatchMode.CONTAINS },

  duration: { value: null, matchMode: FilterMatchMode.GREATER_THAN },
  evse_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
  energy_kwh: { value: null, matchMode: FilterMatchMode.GREATER_THAN },
  power_lower_than_requested_percent: { value: null, matchMode: FilterMatchMode.GREATER_THAN },
  peak_power_kw: { value: null, matchMode: FilterMatchMode.GREATER_THAN },
});

const pageCount = ref<number>(0);
const pageNumber = ref<number>(0);
const ordering = ref<string | null>('-session_end');

const fetchChargingSessionsOptions = computed<ChargeParkQueryParams>(() => {
  let options = {
    pageNumber: pageNumber.value + 1,
    pageSize: DEFAULT_CHARGING_SESSIONS_PAGE_SIZE,
    ordering: ordering.value ?? undefined,
  } as ChargeParkQueryParams;
  for (let filterOption in filters.value) {
    //@ts-ignore
    const filter = filters.value[filterOption];

    let key_suffix = '';
    const val = filter.value;
    switch (filter.matchMode) {
      case 'lt':
        key_suffix = '_less_than';
        break;
      case 'gt':
        key_suffix = '_greater_than';
        break;
      case 'contains':
      default:
        break;
    }
    //@ts-ignore
    options[filterOption + key_suffix] = val;
  }
  //@ts-ignore
  if (options.global) options.search = options.global;
  options.session_start_greater_than = fiterStartTime.value;
  options.session_end_less_than = filterEndTime.value;
  if (props.charge_park_id) options.charge_park_id = String(props.charge_park_id);
  if (props.system_id) options.system_id = String(props.system_id);
  return options;
});

const emit = defineEmits(['showDetails']);
const onRowSelect = (event: any) => {
  const route = `/${CHARGING_SESSIONS}/${event.data.id}`;
  if (event.originalEvent.ctrlKey) {
    const routeData = router.resolve(route);
    window.open(routeData.href, '_blank');
  } else {
    console.log("onRowSelect", props)
    if (!props.system_id && !props.charge_park_id) {
      router.push(route);
    }
    emit('showDetails', event.data.id);
  }
};
async function fetchChargingSessions() {
  await chargingSessionsStore
    .fetchChargingSessions(fetchChargingSessionsOptions.value)
    .catch((res) => {
      if (isAxiosError(res) && res.response?.status === 403) {
        notAuthorized.value = true;
      }
    })
    .finally(() => {
      loading.value = false;

      pageCount.value = chargingSessionsMeta.value.count;
    });
}

function onPageChange(event: PageState) {
  pageNumber.value = event.page;
  fetchChargingSessions();
}

function onSort(event: DataTableSortEvent) {
  ordering.value = `${event.sortOrder === 1 ? '' : '-'}${event.sortField}`;
  fetchChargingSessions();
}

async function applyStartDateAndTimeFilter(val: string) {
  await fetchChargingSessions();
}

async function applyEndDateAndTimeFilter(val: string) {
  await fetchChargingSessions();
}

const openAssetPage = (event: any, id: number) => {
  const route = `/${HARDWARE_SYSTEMS}/${id}`;
  if (event.ctrlKey) {
    const routeData = router.resolve(route);
    window.open(routeData.href, '_blank');
  } else {
    router.push(route);
  }
};

watch(
  filters,
  async () => {
    loading.value = true;
    await fetchChargingSessions();
  },
  { deep: true }
);
onMounted(async () => {
  await fetchChargingSessions();
});
</script>

<style lang="scss" scoped>
.organisation {
  width: 120px;
}

.p-input-icon-left {
  width: 65%;

  .p-inputtext {
    width: 100%;

    &::placeholder {
      color: $text-medium;
      font-size: 14px;
      line-height: 16px;
      padding-top: 5px;
    }
  }
}

.p-checkbox {
  margin: 16px 12px 12px 16px;

  .p-checkbox-box {
    border-color: $grey-medium-light;
  }
}

.unassigned-label {
  color: $accent;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 16px 12px;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check::before {
  top: 8px;
  left: 2px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $accent;
  background: $accent;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $accent;
  background: $accent;
  color: #ffffff;
}

.p-badge {
  &.unassigned-badge {
    background: $accent;
    color: #fff;
    margin: 12px 16px 12px 0;
  }
}
</style>
