import { defineStore } from 'pinia'; 
import { fetchChargingSession, fetchChargingSessions } from './chargingSessions.api';
import type { ChargeParkQueryParams, ChargingSession, ChargingSessionDetails, ChargingSessionMeta } from '@/models/chargingSessions.model';


interface ChargingSessionsState {
  singleChargingSession: ChargingSessionDetails,
  chargingSessions: ChargingSession[];
  chargingSessionsMeta: ChargingSessionMeta;
  isFetchingData: boolean;
}

export const useChargingSessionsStore = defineStore('chargingSessions', {
  state: (): ChargingSessionsState => ({
    singleChargingSession:{
      id: 0,
      session_id: '',
      system_id: 0,
      system_name: '',
      charge_park_name: '',
      charge_park_id: 0,
      connector_id: 0,
      session_start: '',
      session_end: '',
      start_soc_percent: 0,
      end_soc_percent: 0,
      energy_kwh: 0,
      reason_session_end: '',
      duration: '',
      peak_power_kw:0,
      power_lower_than_requested_percent:0,
      evse_id: null,
      mac_car: "",
      evsp: "",
      average_power: "",
      payment_method: "",
      payment_other: ""
    },
    chargingSessions: [],
    chargingSessionsMeta: {
      count: 0,
      next: null,
      previous: null,
    },
    isFetchingData: false
  }),
 

  actions: {
    async fetchChargingSessions( params: ChargeParkQueryParams) { 
        this.isFetchingData = true;

        return await fetchChargingSessions( params)
          .then((response) => {
            const { count, previous, next, results } = response.data;
            this.chargingSessions = results;
            this.chargingSessionsMeta = { count, previous, next };
            this.isFetchingData = false;
            return response;
          })
          .finally(() => (this.isFetchingData = false));
    },
    async fetchChargingSession(chargingSessionID: ChargingSessionDetails['id']) { 
      this.isFetchingData = true;

      return await fetchChargingSession(chargingSessionID)
        .then((response) => {
          const result = response.data;
          this.singleChargingSession = result;
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
  },
  },
});
