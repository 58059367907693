<template>
  <page-header :title="t('chargingSessions.title')" :overrideBackButtonFunction="backBtnPressed" show-back-button />
  <div class="container container-grey grid grid-cols-2 gap-4">
    <Panel
      :header="t('chargingSessions.chargingSession') + ' - ' + singleChargingSession.session_id"
    >
      <div class="flex flex-col w-full divide-solid divide-y">
        <div class="grid grid-cols-2 gap-4">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.system_name')"
            :value="singleChargingSession.system_name"
          ></ChargingSessionDetailItem>

          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.asset_serial')"
            :value="sessionsChargerSerial"
          ></ChargingSessionDetailItem>
        </div>

        <ChargingSessionDetailItem
          class="col-span-2"
          :title="$t('hardwareSystem.hardwareSystemDetails.custom_id')"
          :value="sessionsChargerInventoryNr"
        ></ChargingSessionDetailItem>
        <div class="grid grid-cols-2 gap-4">
          
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.connector_id')"
            :value="singleChargingSession.connector_id"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.evse_id')"
            :value="singleChargingSession.evse_id"
          ></ChargingSessionDetailItem>
        </div>

        <div class="grid grid-cols-2 gap-4">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.session_start')"
            :value="singleChargingSession.session_start"
            isDateTime
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.session_end')"
            :value="singleChargingSession.session_end"
            isDateTime
          ></ChargingSessionDetailItem>


        </div>
        <div class="grid grid-cols-2 gap-4">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.duration')"
              :value="relativeDuration"
            ></ChargingSessionDetailItem>
            
        <ChargingSessionDetailItem 
          :title="$t('chargingSessions.details.mac_car')"
          :value="singleChargingSession.mac_car"
          breakAll
        ></ChargingSessionDetailItem>
          </div> 


        <div class="grid grid-cols-2 gap-4">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.payment_method')"
            :value="singleChargingSession.payment_method"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.payment_other')"
            :value="singleChargingSession.payment_other"
          ></ChargingSessionDetailItem>
        </div>
      </div>
    </Panel>
    <Panel :header="t('chargingSessions.powerAndEnergy')">
      <div class="flex flex-col w-full divide-solid divide-y">
        <div class="grid grid-cols-2 gap-4">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.start_soc_percent')"
            :value="singleChargingSession.start_soc_percent.toFixed(2)"
            unit-suffix="%"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.end_soc_percent')"
            :value="singleChargingSession.end_soc_percent.toFixed(2)"
            unit-suffix="%"
          ></ChargingSessionDetailItem>
        </div>

        <div class="grid grid-cols-2 gap-4">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.energy_kwh')"
            :value="singleChargingSession.energy_kwh.toFixed(4)"
            unit-suffix="kWh"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.peak_power_kw')"
            :value="singleChargingSession.peak_power_kw.toFixed(0)"
            unit-suffix="kW"
          ></ChargingSessionDetailItem>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.average_power')"
            :value="parseFloat(singleChargingSession.average_power).toFixed(0)"
            
            unit-suffix="kW"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.power_lower_than_requested_percent')"
            :value="singleChargingSession.power_lower_than_requested_percent.toFixed(2)"
            
            unit-suffix="%"
          ></ChargingSessionDetailItem>
        </div>
        <ChargingSessionDetailItem
          class="col-span-2"
          :title="$t('chargingSessions.details.evsp')"
          :value="singleChargingSession.evsp"
          breakAll
        ></ChargingSessionDetailItem>

        <div class="grid grid-cols-2 gap-4"></div>

        <ChargingSessionDetailItem
          class="col-span-2"
          :title="$t('chargingSessions.details.reason_session_end')"
          :value="singleChargingSession.reason_session_end"
        ></ChargingSessionDetailItem>
      </div>
    </Panel>
    <!--<TelemetryWidget
    :systemId="singleChargingSession.system_id"
    :widget=""
    ></TelemetryWidget>-->
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import Panel from 'primevue/panel';
import { useI18n } from 'vue-i18n';

import { getDurationFromSec } from '@/utils/dateTimeFormat';
import { useChargingSessionsStore } from '@/stores/admin/chargingSessions/chargingSessions.store';
import ChargingSessionDetailItem from './ChargingSessionDetailItem.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
//import TelemetryWidget from '@/components/hardwareSystems/controlPanel/widgets/telemetry/TelemetryWidget.vue';
//import {fetchAvailableTelemetryChannels} from '@/stores/admin/hardwareSystems/hardwareSystems.api';
const { t } = useI18n();
const route = useRoute();

const emit = defineEmits(['closeDetailsPage']);
const systemStore = useHardwareSystemsStore();

const chargingSessionStore = useChargingSessionsStore();
const relativeDuration = computed(
  () =>
    getDurationFromSec(singleChargingSession.value.duration) +
    ` (${singleChargingSession.value.duration} ${t('seconds')})`
);
const props = defineProps<{
  sessionID: number;
}>(); 

const { singleChargingSession } = storeToRefs(chargingSessionStore);
const { singleSystem } = storeToRefs(systemStore);

const sessionsChargerInventoryNr = ref<string>('');
const sessionsChargerSerial = ref<string>('');
//const availTelemetryChannels = ref();
const backBtnPressed = ()=>{
  emit('closeDetailsPage')
}
 

onMounted(async () => {
  chargingSessionStore.fetchChargingSession(props.sessionID).then(async () => {
    if (singleChargingSession.value.system_id > 0) {
      await systemStore.fetchSingleSystem(singleChargingSession.value.system_id);
      /*availTelemetryChannels.value = await fetchAvailableTelemetryChannels(singleChargingSession.value.system_id, {
        startTime: singleChargingSession.value.session_start,
        endTime: singleChargingSession.value.session_end 
      });*/
      sessionsChargerInventoryNr.value = singleSystem.value.custom_id;
      sessionsChargerSerial.value = singleSystem.value.nidec_id;
    }
  });
});

onUnmounted(() => {
  sessionsChargerInventoryNr.value = '';
  sessionsChargerSerial.value = '';
});
</script>
<style scoped lang="scss">
.p-panel {
  box-shadow: none;
  border: 1px solid $grey-medium-light;

  &.no-buttons {
    :deep(.p-panel-header) {
      padding: 18px 16px;
    }
  }
}
:deep(.p-panel-header) {
  padding: 16px 16px;
  background-color: $grey-light;
  border-bottom: 1px solid $grey-medium-light;
}

:deep(.p-panel-content) {
  padding: 4px 16px;
}

:deep(.p-panel-title) {
  font-size: 16px;
  color: $text-dark;
}
</style>
