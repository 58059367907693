import { defineStore } from 'pinia';
import * as api from './controlPanel.api';
import type { Dashboard, Panel } from '@/models/controlPanel/dashboard';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { ProductType } from '@/models/productTypes.model';
import { DataClient, type LastDataEvents } from '@/logic/controlPanel/dataClient';
import { pathMatcherWithWildcards } from '@/logic/controlPanel/productType';

const pollIntervalMs = import.meta.env.DEV ? 2000 : 10000;

export const useControlPanelStore = defineStore('controlPanel', {
  state: () => ({
    systemId: null as HardwareSystem['id'] | null,
    hardwareSystem: null as HardwareSystem | null,
    productType: null as ProductType | null,

    dashboard: null as Dashboard | null,
    activePanelId: null as Panel['id'] | null,

    dataClient: new DataClient(pollIntervalMs),

    initializationError: null as string | null,
  }),
  getters: {
    getSettingByPath: (state) => {
      return (path: string) =>
        state.productType?.properties.settings.find((s) => pathMatcherWithWildcards(path, s.path));
    },
    getTelemetryByChannel: (state) => {
      return (channel: string) => state.productType?.properties.channels[channel];
    },
  },
  actions: {
    async initialize(systemId: HardwareSystem['id']): Promise<void> {
      if (this.systemId === systemId) return;

      try {
        this.hardwareSystem = await api.fetchHardwareSystem(systemId);
        const result = await Promise.all([api.fetchProductType(this.hardwareSystem.product_type_id),
        api.fetchDashboard(
          this.hardwareSystem.default_dashboard_id,
          systemId
        )])
        this.productType =result[0]; 
        this.dashboard = result[1];

        this.systemId = systemId;
        this.initializationError = null;
      } catch (e) {
        if (e instanceof Error) this.initializationError = e.message;
        else this.initializationError = String(e);
        console.error(e);
        return Promise.reject(e);
      }
    },

    async startPolling(dashboardId?: number, panelId?: string): Promise<void> {
      if (!this.systemId) return;
      await this.dataClient.startPolling(this.systemId, dashboardId, panelId);
    },

    async stopPolling(): Promise<void> {
      this.dataClient.stopPolling();
    },

    async refreshData(options?: { useCacheIfAvailable?: boolean }): Promise<void> {
      await this.dataClient.sendSingleRequest(options);
    },

    getSettingValueFromCache(path: string): LastDataEvents['settings'][number] | undefined {
      return this.dataClient.getFromCache('setting', path);
    },

    getTelemetryValueFromCache(path: string): LastDataEvents['telemetry'][number] | undefined {
      return this.dataClient.getFromCache('telemetry', path);
    },

    async applyChange(path: string, value: any): Promise<void> {
      if (!this.hardwareSystem) return;
      await api.applyPatch(this.hardwareSystem.id, [{ path, value }]);
    },

    async executeCommand(path: string, force: boolean, args?: object): Promise<void> {
      if (!this.hardwareSystem) return;
      return api.executeCommand(this.hardwareSystem.id, path, force, args);
    },

    async destroy(): Promise<void> {
      this.dataClient.stopPolling();
      this.$reset();
    },
  },
});
