{
  "actions": "Actions",
  "add": "Add",
  "address": "Address",
  "never": "Never",

  
  "chargingSessions": {
    "title": "Charging Sessions",
    "empty": "No Charging Sessions available",
    "sessionID": "Session ID",
    "evseID": "EVSE ID",
    "duration": "Duration",
    "soc": "SOC",
    "energyCharged": "Energy charged",
    "peakPower": "Peak power",
    "powerLowerThanRequestetedPercent": "Power too low",
    "chargingSession": "Charging Session",
    "powerAndEnergy": "Power & Energy",
    "details":{
      "id": "ID",
      "session_id": "Session ID",
      "system_id": "System ID",
      "system_name": "Asset Name",
      "asset_serial": "Asset Serial Number",
      "charge_park_name": "Site Name",
      "charge_park_id": "Site ID",
      "connector_id": "Connector ID",
      "session_start": "Session Start",
      "session_end": "Session End",
      "start_soc_percent": "Start State of Charge",
      "end_soc_percent": "End State of Charge",
      "energy_kwh": "Energy Charged",
      "reason_session_end": "Status",
      "peak_power_kw": "Peak Power",
      "power_lower_than_requested_percent": "Power lower than requested",
      "evse_id": "EVSE ID",
      "duration": "Duration",
      "average_power": "Average Power",
      "payment_method": "Payment Method",
      "payment_other": "Payment details",
      "evsp": "EVSP",
      "mac_car": "MAC Address (Vehicle)"
    }
  },
  "alert": {
    "assets": {
      "compatible": "Compatible assets",
      "empty": "No assets selected",
      "loading": "Loading assets. Please wait...",
      "organisation": "Organisation"
    },
    "ccus": "CCUs",
    "deleteInfo": "It will be removed from the system permanently",
    "deleted": "Selected alerts were successfully deleted",
    "delivery": "Alert Delivery",
    "deliveryInfo": "Configure alert delivery preferences. Even if no contact is selected or max rate is hit, the alert will still show up in the alert history.",
    "deliveryRateLimit": "Delivery Rate Limit",
    "description": {
      "main": "Only Alerts of your own organization are shown here."
    },
    "disabled": "Selected alerts were successfully disabled",
    "empty": "No alert created yet",
    "enabled": "Selected alerts were successfully enabled",
    "lastTriggered": "Last Triggered",
    "maxRate": "Max Rate",
    "never": "Never",
    "newAlert": "New Alert",
    "parameter": {
      "amount": "Amount",
      "condition": "Condition",
      "drop_percent": "Drop",
      "duration": "Duration",
      "enabled": "Enabled",
      "someEnum": "Some Enum",
      "thresh": "Temperature Threshold",
      "threshold": "Time Threshold",
      "timeframe": "Timeframe"
    },
    "parameters": "Parameters",
    "parametersDescription": "Configure the template",
    "perDay": "per day",
    "sendOnWeekends": "Send On Weekends",
    "siteDescription": "Which sites should this alert apply to",
    "sites": "Sites",
    "temperature": "Temperature",
    "template": {
      "description": "Select the type of alert you would like to configure",
      "empty": "No alert templates available",
      "loading": "Loading alert templates. Please wait..."
    },
    "title": "Alert"
  },
  "alerts": "Alerts",
  "allTime": "All Time",
  "apply": "Apply",
  "back": "Back",
  "batchActions": {
    "delete": "Delete Selected",
    "disable": "Disable Selected",
    "enable": "Enable Selected"
  },
  "branding": {
    "actions": {
      "create": "Create Branding"
    },
    "list": {
      "empty": "No brandings found"
    },
    "title": "Branding"
  },
  "cancel": "Cancel",
  "changeAccess": "Change Access",
  "chargePark": {
    "access": "Access",
    "accessAndPayments": "Access & Payments",
    "accessType": "Access Type",
    "accessible": "Accessible",
    "addChargers": "Add Chargers",
    "addRule": "Add Rule",
    "chargeParkDetails": {
      "address": "Address",
      "contact_email": "Email",
      "contact_name": "Name",
      "contact_phone": "Phone Number",
      "country_code": "Country",
      "custom_id": "Inventory Number",
      "latitude": "Latitude",
      "longitude": "Longitude",
      "manufacturer": "Manufacturer",
      "model": "Model",
      "name": "Site Name",
      "nidec_id": "Serial Number",
      "operator_address": "Operator Address",
      "operator_name": "Operator Name",
      "owner_organisation_name": "Organisation",
      "service_ended_at": "Service Ended",
      "service_started_at": "Service Started"
    },
    "customReceiptMessage": "Custom receipt message",
    "empty": "No sites in this organization",
    "includedSites": "Included Sites",
    "latitude": "Latitude",
    "longitude": "Longitude",
    "noSitesFound": "No sites found",
    "operatorAddress": "Operator address",
    "operatorName": "Operator name",
    "organisation": "Organization",
    "owner": "Owner",
    "payment": "Payment",
    "paymentMethods": "Payment Methods",
    "performanceAndMetrics": "Performance & Metrics",
    "rules": "Rules",
    "rulesInfo": "By default the charger is not accessible",
    "schedule": "Schedule",
    "siteName": "Site name",
    "sitesAmount": "Sites amount",
    "stationType": "Station Type",
    "tableEmpty": "Site list was not found",
    "timeFrom": "Time from",
    "timeInfo": "Times are local and based on the site time-zone",
    "timeTo": "Time to",
    "title": "Site"
  },
  "chargeParks": "Sites",
  "chargers": "Chargers",
  "contact": {
    "activeContacts": "Active Contacts",
    "addContact": "Add Contact",
    "code": "Code",
    "contactInfo": "Manage how we can contact you",
    "deleteInfo": "You will not receive alerts on this address",
    "description": {
      "main": "Only Contacts of your own organization are shown here."
    },
    "disabledInfo": "Alerts and reports via this contact will be disabled for now",
    "email": "email",
    "empty": "No contacts created yet",
    "enabledInfo": "Will receive alerts and reports via this contact",
    "phoneNumber": "Phone Number",
    "pickerInfo": "Who should be notified of a breach (Multiple allowed)",
    "resend": "Resend",
    "title": "Contact",
    "typeEmail": "Please check your e-mail box for the verification code",
    "typeSms": "Please check your mobile phone for the verification code",
    "verificationResend": "Resend verification code",
    "verified": "Verified",
    "verify": "Verify",
    "verifyContact": "Verify Contact"
  },
  "contacts": "Contacts",
  "control": "Control",
  "country": {
    "title": "Country"
  },
  "create": "Create {itemName}",
  "createdAt": "Created At",
  "createdByOrganization": "Created by Organization",
  "dashboard": {
    "controlPanel": {
      "command": {
        "actions": {
          "cancel": "Cancel",
          "execute": "Execute",
          "force": "Force Execution",
          "warning": "Warning"
        },
        "conflictMessages": {
          "existingCommand": "There is an existing command ({commandId}) that is currently being executed. Applying will cancel the existing command and execute this one.",
          "systemOffline": "The asset is currently offline. Applying will execute this command when the asset comes back online."
        },
        "errors": {
          "generic": "There was an error executing this command"
        }
      },
      "setting": {
        "actions": {
          "cancel": "Undo"
        },
        "error": {
          "updateFailed": "There was an error updating this setting"
        },
        "status": {
          "pendingChange": "Pending change"
        }
      },
      "tabs": {
        "general": "General",
        "chargingSessions": "Charging Sessions",
        "firmware": "Firmware",
        "events": "Event History",

        "activeErrors": "Active Errors",
        "logs": "Logs",
        "status": "Operational Status",
        "settings": "Settings"
      }
    },
    "page": {
      "title": "My Dashboard"
    },
    "telemetry": {
      "table": {
        "timestamp": "Time",
        "value": "Value"
      },
      "chart": {
        "nodata": "No data available for the selected time range",
        "title": "Historical Data"
      }
    }
  },
  "dashboards": {
    "widgets": {
      "info": {
        "interactive": "Interactive Chart"
      }
    }
  },
  "dataExport": {
    "createdAt": "Created At",
    "dataExportJob": "Data Export Job",
    "dataTypes": "Data Types",
    "dataTypesDescription": "What data should be included in the export (at least one)",
    "dataTypesNames": {
      "configuration": "Configuration",
      "events": "Events",
      "logs": "Logs",
      "telemetry": "Telemetry"
    },
    "deletedExport": "This export has been deleted because it's more than one month old",
    "description": {
      "main": "Only Export Jobs of your own organization are shown here."
    },
    "empty": "No data exports created yet",
    "includedAssets": "Included Assets",
    "includedData": "Included Data",
    "newDataExport": "New Export Job",
    "status": "Status",
    "timeRange": "Time Range",
    "timeRangeEnd": "Time Range End",
    "timeRangeInfo": "All times are your local time ({userTimezone})",
    "timeRangeStart": "Time Range Start",
    "title": "Data Export"
  },
  "dataExports": "Data Exports",
  "delete": "Delete {itemName}",
  "deleteWarning": "Are you sure?",
  "deletedAt": "Delete date",
  "deletedBy": "Deleted by",
  "description": "Description",
  "details": "Details",
  "dialogSteps": {
    "assets": "Assets",
    "configuration": "Configuration",
    "contacts": "Contacts",
    "defaultRate": "Default Rate",
    "information": "Information",
    "playlist": "Playlist",
    "preview": "Preview",
    "sites": "Sites",
    "specialRates": "Special Rates",
    "template": "Template"
  },
  "disabled": "Disabled",
  "download": "Download",
  "downtimes": {
    "affectedAssets": "Affected Assets",
    "deleteInfo": "It will be removed from the system permanently",
    "description": "You can put any unplanned outages here. Those \"acts of god\" will not count against calculated uptime if the charger reports an outage in that period. The data here is only used for uptime computation and reporting and has no other effect. ",
    "downtime": "Unplanned Outage",
    "downtimeInfo": "Enter the start and end times for the unplanned outage. Your timezone is set to {userTimezone}.",
    "empty": "No unplanned outages added yet",
    "endTime": "End Time",
    "newDowntime": "New Unplanned Outage",
    "reason": "Reason",
    "selectEndTime": "Select end time",
    "selectStartTime": "Select start time",
    "startTime": "Start Time",
    "title": "Unplanned Outages"
  },
  "dragAndDrop": "Drag and drop files here to upload",
  "edit": "Edit {itemName}",
  "email": "E-mail",
  "enabled": "Enabled",
  "error": "Error",
  "errors": {
    "startDatetimeLessThanEndDatetime": "Start datetime should be smaller than end datetime!"
  },
  "file": "File",
  "filterBy": "Filter by",
  "frequency": "Frequency",
  "general": "General",
  "generated": "Generated",
  "hardwareSystem": {
    "emptyActiveErrors": "No active errors found",
    "assetName": "Asset name",
    "assetType": "Asset Type",
    "assign": "Assign",
    "unassign": "Unassign",
    "unassignWarning": "Unassigning this charger will cause a loss of all associated Playlists, Brandings, Tariffs, Alerts and Reports!",
    "assignInfo": "Assigning an asset to a site automatically changes ownership of the asset to the organization owning the site.",
    "assignTo": "Assign To Site",
    "noData": "No data available",
    "unassignFromSite": "Unassign From Site",
    "chargeParkName": "Site",
    "charger": "Charger",
    "connector": "Connector",
    "compatibleSystems": "Compatible Assets",
    "contact": {
      "contact_email": "Contact Email",
      "contact_name": "Contact Name",
      "contact_phone": "Contact Phone Number",
      "name": "Site Name"
    },
    "currentPlaylist": "Current Playlist",
    "customId": "Inventory Number",
    "delegate": "Delegate",
    "delegateInfo": "Delegating a charger to another organization allows admins in that organization to manage it",
    "delegateTo": "Delegate To Organization",
    "empty": "No asset found",
    "errors": {
      "endedAt": "\"Service Ended\" should be bigger than \"Service Started\"",
      "startedAt": "\"Service Started\" should be smaller than \"Service Ended\""
    },
    "hardwareSystemDetails": {
      "address": "Address",
      "contact_email": "Email",
      "contact_name": "Name",
      "contact_phone": "Phone Number",
      "country_code": "Country",
      "custom_id": "Inventory Number",
      "latitude": "Latitude",
      "longitude": "Longitude",
      "manufacturer": "Manufacturer",
      "model": "Model",
      "name": "Site Name",
      "nidec_id": "Serial Number",
      "operator_address": "Operator Address",
      "operator_name": "Operator Name",
      "owner_organisation_name": "Organisation",
      "service_ended_at": "Decommissioning Date",
      "service_started_at": "Commissioning Date"
    },
    "inventoryNo": "Inventory No.",
    "lastOnline": "Last Online",
    "loading": "Loading Assets. Please wait...",
    "model": "Model",
    "nidecId": "Serial Number",
    "nidecIds": "Serial Numbers",
    "numberOfSystems": "Number of Assets",
    "ownerOrganisationName": "Current Organization",
    "siteContactInfo": "Site Contact Info",
    "connectorStatus": "Connector Status",
    "status": "BYS Status",
    "systemStatus": {
      "infoMessage": "The system is considered offline if it has not sent data for 20 minutes.",
      "offline": "Offline",
      "online": "Online"
    },
    "systems": "Assets",
    "title": "Asset",
    "unnamedAsset": "Unnamed asset, serial number: ",
    "updateEmpty": "No firmware update found",
    "updateSystem": "Update Asset",
    "version": "Current Version"
  },
  "hardwareSystems": "Assets",
  "history": {
    "errorCode": "Error Code",
    "severity": "Severity",
    "aggregateErrors": "Aggregate Errors",
    "count": "Count",
    "empty": "No events in this timeframe",
    "errors": {
      "timeFrom": "\"Time from\" should be smaller than \"Time to\"",
      "timeTo": "\"Time to\" should be bigger than \"Time from\""
    },
    "first_occurrence": "First occurrence",
    "last_occurrence": "Last occurrence",
    "content": "Content",
    "totalCount": "Total Count",
    "eventDetails": "Event Details",
    "eventHistory": "Event History",
    "noData": "No data entries found",
    "ticketLabel": "View ticket",
    "title": "Event"
  },
  "hours": "Hours",
  "id": "ID",
  "info": "Info",
  "interactions": {
    "copyToClipboard": {
      "error": "Failed to copy to clipboard",
      "success": "Copied to clipboard"
    }
  },
  "item": "Item",
  "items": "Items",
  "last24h": "Last 24h",
  "last3months": "Last 3 months",
  "lastMonth": "Last month",
  "lastWeek": "Last week",
  "lastYear": "Last year",
  "loading": "Loading...",
  "location": "Location",
  "locations": "locations",
  "log": {
    "actions": "Actions",
    "empty": "No log traces uploaded yet",
    "timeUploaded": "Time uploaded"
  },
  "logout": "Logout",
  "logs": "Logs",
  "lookupFromAddress": "Lookup From Address",
  "mapInfo": "Click on the map to set a new latitude and longitude",
  "media": "Media",
  "mediaLibrary": {
    "status": "Status",
    "deleteBatchInfo": "Selected media will be removed from the system permanently",
    "deleteInfo": "It will be removed from the system permanently",
    "description": "A place to store all of the media files to use in campaigns and branding",
    "editMedia": "Edit media",
    "empty": "No media uploaded yet",
    "failedIds": "Some media failed to be deleted:",
    "fileExtension": "File extension",
    "fileSize": "File size",
    "linkToFile": "Link to file",
    "loading": "Loading media data. Please wait...",
    "media": "Media",
    "mediaFile": "Media File",
    "metadata": "Metadata",
    "playlist": "Playlist",
    "playlists": "Playlists",
    "tableEmpty": "Media list was not found",
    "thumbnail": "Thumbnail",
    "title": "Media Library",
    "uploadFile": "Upload file",
    "uploadNewMedia": "Upload new media",
    "usage": "Usage"
  },
  "members": "Members",
  "minutes": "Minutes",
  "monitoring": {
    "activeMonitoring": "Active Monitoring",
    "alertHistory": "Alert history",
    "alerts": "Alerts",
    "configuration": "Configuration",
    "history": "History",
    "historyEmpty": "No alert history yet",
    "severity": "Severity"
  },
  "more": "More",
  "myDashboardLink": "Dashboard",
  "name": "Name",
  "names": "names",
  "next": "Next",
  "none": "None",
  "notAuthorized": "Error, you are not authorized to view this content",
  "or": "or",
  "organisation": {
    "availableFeatures": "Available Features",
    "availableFeaturesInfo": "This allows you to define the features that members of the new organization.",
    "createNew": "Create new organization",
    "currentOwner": "Current Owner",
    "edit": "Edit Organization",
    "empty": "No organizations created yet",
    "errorName": "Organization name is not valid",
    "featureDescription": {
      "1": "Enable the use of Whitelists to allow free charging on a set of chargers for a defined group of people",
      "10": "Enable the export of data (telemetry, events, ...)",
      "11": "Enable the configuration of the charger HMI via the BYS",
      "2": "Enable grouping of assets (e.g. an EV Charger) into sites (e.g. a chargepark, parking lot or microgrid)",
      "3": "Enable administrative features (e.g. upload of new update packages)",
      "4": "Enable the creation of suborganizations for this organization",
      "5": "Enable the creation and sharing of custom dashboards",
      "6": "Enable the management of content shown on the HMI Screen ad the large advertisement screen of the assets",
      "7": "Enable active monitoring (alarm e-mails and SMS) for assets and sites",
      "8": "Enable the creation of government-mandated and status reports via email",
      "9": "Enable the management of charging tariffs via the BYS"
    },
    "features": "Features",
    "hierarchy": "Organization hierarchy is grouped by parent and child organizations. Parent organizations can access and configure all assets of their child organizations.",
    "loading": "Loading organizations data. Please wait...",
    "master": "Master organization",
    "my": "My Organization",
    "name": "Organization name",
    "parent": "Parent Organization",
    "scope": "The parent organization will have access to all the assets of the new organization",
    "selectParent": "Select the parent of this organization",
    "title": "Organization"
  },
  "organisations": "Organizations",
  "organisationsAndUsers": "Organizations & Users",
  "owner": "Owner",
  "owners": "owners",
  "permissions": {
    "info": {
      "parentOrgOwnsResource": {
        "deleteNotAllowed": "This {resource} was created by a parent organization and can't be deleted",
        "editNotAllowed": "This {resource} was created by a parent organization and can't be edited"
      }
    }
  },
  "phoneNumber": "Phone number",
  "playlist": "Playlist",
  "playlists": {
    "addMoreDescription": "Use the media browser to add more media to the playlist",
    "deleteInfo": "It will be removed from the system permanently",
    "duration": "Duration",
    "empty": "No playlists created yet",
    "mediaItemNotProcessed": "Unprocessed",
    "image": "Image",
    "images": "Images",
    "mediaBrowser": "Media Browser",
    "mediaItems": "Media Items",
    "newPlaylist": "New Playlist",
    "playlist": "Playlist",
    "repeat": "Repeat",
    "scaling": "Scaling",
    "scalingDescription": {
      "fill": "<b>Fill</b> will stretch the image according to the screen width. Depending on the image ratio, top and bottom parts of the image might be truncated or filled with black borders.",
      "fit": "<b>Fit</b> will stretch the image according to the screen height. Depending on the image ratio, left and right parts of the image might be truncated or filled with black borders.",
      "general": "Scaling decides how the image/video will be displayed:",
      "stretch": "<b>Stretch</b> will scale the image or video such that it fills the screen. The image might be distorted."
    },
    "singleImageInfo": "As long as only one image is in the playlist, it will be displayed forever, independent of the duration",
    "siteDescription": "Which sites should this playlist apply to",
    "sites": "Sites",
    "title": "Playlists",
    "totalDuration": "Total Duration",
    "updated": "Updated",
    "video": "Video",
    "videos": "Videos",
    "warnNotProcessedMedia": "<b>Warning:</b> Not processed media will not be available in the playlist!"
  },
  "preferences": "Preferences",
  "productType": {
    "title": "Product type"
  },
  "remove": "Remove",
  "removeSelected": "Remove selected",
  "report": {
    "contact": {
      "description": "Who should we send the report to? (The report can also be downloaded manually by going to report history). Sms contacts will receive a download link to the report."
    },
    "coveredTimespan": {
      "description": "The timespan that is covered by the report. The included timespan will start at midnight on the start date and end at the end of the end date day. <br /> Report days can be no earlier than 2 days before now. They are based on historical data, which is aggregated at night. <br /> Report times are UTC-0 to make it possible to group assets from multiple timezones in one report.",
      "title": "Covered timespan"
    },
    "deleteInfo": "It will be deleted from the system permanently",
    "description": {
      "additional": "Only Reports of your own organization are shown here.",
      "main": "Reports allow you to set up recurring summaries of your assets and comply with industrial & governmental requirements."
    },
    "empty": "No report created yet",
    "endDate": "End Date",
    "failedIds": "Some Reports failed to be deleted:",
    "firstReport": "First Report",
    "historyEmpty": "No report history yet",
    "newReport": "New Report",
    "nextReport": "Next Report",
    "preview": {
      "noContactSelected": "Warning: No contact selected. This report will not <br /> be mailed out and only be available for download."
    },
    "reportType": {
      "instantDescription": "An instant report is created right now. You can select the covered period of the report.",
      "periodicDescription": "A periodic report will be scheduled at a regular frequency. It will be delivered to your contacts every week, month, quarter or year.",
      "title": "Report type"
    },
    "schedule": {
      "description": "Configure report generation schedule. <br /> Report times are UTC-0 to make it possible to group assets from multiple timezones in one report.",
      "title": "Schedule"
    },
    "site": {
      "description": "Which sites should be included in the report",
      "title": "Sites"
    },
    "startDate": "Start Date",
    "template": {
      "description": "Select the type of report you would like to configure",
      "empty": "No report templates available",
      "loading": "Loading report templates. Please wait..."
    },
    "title": "Report"
  },
  "reports": "Reports",
  "rfidTag": {
    "addRfidTag": "Add RFID Tag",
    "addedToList": "Added to list",
    "deleteBatchInfo": "The tags will be removed from all whitelists and the owners will not be able to charge anymore",
    "deleteInfo": "The tag will be removed from all whitelists and the owner will not be able to charge anymore",
    "disabledDescription": "Will not be able to charge even if added to a whitelist",
    "empty": "No RFID tags in list",
    "enabledDescription": "Once added to a whitelist can charge directly",
    "failedIds": "Some RFID tags failed to be deleted:",
    "id": "RFID Tag Id",
    "loading": "Loading RFID Tags. Please wait...",
    "tableEmpty": "No RFID tags in whitelist",
    "title": "RFID Tag"
  },
  "rfidTags": "RFID Tags",
  "rules": {
    "alphaNum": "{field} must contain only alphanumeric characters",
    "alphanumeric": "{field} must contain only alphanumeric characters and spaces",
    "asciiCharacters": "{field} must contain only ASCII characters (20-126)",
    "asciiCharactersWithNewLine": "{field} must contain only ASCII characters or new line.",
    "chargeParkRequired": "Site is required",
    "coordinate": "{field} should contain only decimal characters and should have a range from {start} to {end}",
    "dateAndTime": "Date & Time",
    "durationHours": "Hours should be between 0 and 99",
    "durationMinutes": "Minutes should be between 0 and 59",
    "durationSeconds": "Seconds should be between 0 and 59",
    "email": "Enter a valid e-mail",
    "endTime": "End time can't be earlier than or qual to start time",
    "endTimeRequired": "Select End time",
    "fieldIsRequired": "Field is required",
    "firstReportRequired": "First report is required",
    "frequencyRequired": "Select frequency",
    "integer": "integer",
    "maxLength": "Max length of {field} should not be longer than {length} characters",
    "maxValue": "Can't be more than {amount}",
    "minValue": "Can't be less than {amount}",
    "nextReportRequired": "Next report is required",
    "parentRequired": "Select parent organization",
    "phoneNumber": "Phone number should have a + at the beginning and max 14 characters",
    "required": "Enter {field} for {item}",
    "startTime": "Start time can't be later than or qual to end time",
    "startTimeRequired": "Select Start time",
    "systemRequired": "Asset is required",
    "timezoneRequired": "Select timezone",
    "weekdayRequired": "Weekday selection is required."
  },
  "save": "Save",
  "search": "Search",
  "searchBy": "Search by {name}",
  "seconds": "Seconds",
  "select": "Select {name}",
  "selectFile": "Select file",
  "selectRange": "Select range",
  "settings": "Settings",
  "sidebar": {
    "access": "Access",
    "admin": "Admin",
    "alertsAndReports": "Alerts & Reports",
    "brandingAndContent": "Branding & Content",
    "general": "General",
    "infrastructure": "Infrastructure"
  },
  "site": "Site",
  "size": "Size",
  "sms": "Sms",
  "status": "Status",
  "suborganizations": "Suborganizations",
  "tariff": {
    "addSpecialRate": "Add Special Rate",
    "configureDefaultRate": "Configure Default Rate",
    "configureDefaultRateInfo": "This is the rate that is used when no special rate applies",
    "currentTariff": "Current Tariff",
    "defaultRate": "Default Rate",
    "defaultRateDescription": "This is the rate that is used when no special rate applies",
    "deleteInfoWithSites": "The following sites will have no tariff",
    "deleteInfoWithoutSites": "No sites are affected by deletion of this tariff",
    "deleteRateInfo": "The following sites will have no {rateName} rate",
    "description": "Tariffs can be used to define the charging rates for your chargers. If OCPP is configured and active, it will override the BYS tariffs.",
    "empty": "No tariffs added yet",
    "errors": {
      "endDate": "\"End date\" should be bigger or equal to \"Start date\"",
      "startDate": "\"Start date\" should be smaller or equal to \"End date\""
    },
    "minutes": "minutes",
    "newTariff": "New Tariff",
    "noSpecialRates": "No special rates defined",
    "position": "position",
    "rateConflictWarning": "The following rates would conflict with each other: {name}. Please resolve the dates & times when this rate should apply",
    "sitesInfo": "Which sites should use this tariff",
    "specialRate": "Special Rate",
    "specialRateDescription": "These are rates you would like to charge within certain dates, weekdays or times",
    "specialRateTimes": "Times (Local to site)",
    "specialRateTimesDescription": "By default, the tariff is valid the whole day. If you enter a time here, it will be restricted to certain hours.",
    "specialRates": "Special Rates",
    "specialRatesCreateDescription": "These are rates you would like to charge within certain dates, weekdays or times (e.g. a weekend rate, an event rate). These override the default rate.",
    "startAndEndDate": "Start & End Date",
    "startAndEndDateDescription": "By default, the tariff is valid forever. If you enter dates here, it will be restricted to certain days.",
    "tariffDetails": {
      "charge_parks": "Included Sites",
      "chargingDurationCostAndTaxRate": "Charging Duration Cost & Tax Rate",
      "currency": "Currency",
      "currencySymbolPosition": "Currency unit position in receipt",
      "energyCostAndTaxRate": "Energy Cost & Tax Rate",
      "fixedTaxAmountPerSessionEnergy": "Fixed Tax Amount Per Session (Energy)",
      "fixedTaxAmountPerSessionParking": "Fixed Tax Amount Per Session (Parking)",
      "idleFeeAndTaxRate": "Idle Fee & Tax Rate",
      "idleFeeParkingAndTaxRate": "Idle Fee (Parking) & Tax Rate",
      "idleGracePeriod": "Idle Grace Period",
      "min": "min",
      "name": "Tariff Name",
      "preAuthorizationAmount": "Pre-Authorization Amount",
      "sessionFeeAndTaxRate": "Session Fee & Tax Rate"
    },
    "times": "Times",
    "title": "Tariff",
    "weekdays": "Weekdays"
  },
  "tariffs": "Tariffs",
  "template": "Template",
  "templates": "Templates",
  "time": "Time",
  "timeFilter": "Time filter",
  "timezone": "Timezone",
  "title": "Page title",
  "toast": {
    "2000": "Master organization cannot be deleted",
    "2001": "Sorry the parent of the organization can't be changed because the organization you selected is a child of the other organization in the first place",
    "2002": "Cannot assign same organization as parent organization and sub organization",
    "2004": "Cannot change this field in your own organization",
    "added": "added",
    "adding": "adding",
    "assigned": "assigned",
    "unassigned": "unassigned",
    "assignment": "assignment",

    "unassignment": "unassignment",

    "created": "created",
    "creation": "creation",
    "dataExportCreated": "Job successfully created. Please wait a few minutes until your download is ready",
    "delegated": "delegated",
    "delegation": "delegation",
    "deleted": "deleted",
    "deletion": "deletion",
    "disabled": "Selected {name} were successfully disabled",
    "edited": "edited",
    "edition": "edition",
    "enabled": "Selected {name} were successfully enabled",
    "error": "Error during {name} {action}",
    "errorBatch": "Error during selected {name} {action}",
    "errorBatchWhen": "Error when {action} selected {name}",
    "errorParent": "Sorry the parent of the organization can't be changed because the organization you selected is a child of the other organization in the first place",
    "existingEmail": "This email address is already used for another account on the BYS platform. Please use a different email address",
    "installation": "installation",
    "installed": "installed",
    "instantReportCreated": "Your report will be created and sent to your contacts within 10 minutes. Please be patient...",
    "removal": "removal",
    "removed": "removed",
    "resend": "resend",
    "resent": "resent",
    "saved": "saved",
    "saving": "saving",
    "schedule": "schedule",
    "scheduled": "scheduled",
    "statusChange": "status change",
    "success": "{name} was successfully {action}",
    "successBatch": "Selected {name} were successfully {action}",
    "upload": "Upload",
    "uploaded": "uploaded",
    "uploading": "uploading",
    "verification": "verification",
    "verified": "verified"
  },
  "type": "Type",
  "update": {
    "deleteInfo": "If this firmware update has been scheduled on chargers already, those chargers will still be able to install it, even if it's deleted from this list",
    "editUpdate": "Edit firmware update",
    "empty": "No update packages uploaded yet",
    "firmware": "Firmware",
    "firmwareHistory": "Firmware update history",
    "firmwareVersion": "Firmware Version",
    "install": "Install",
    "linkToFile": "Link to file",
    "loading": "Loading firmware updates. Please wait...",
    "packageDescription": "Package description",
    "packageVersion": "Package version",
    "productTypeId": "Product type ID",
    "productTypeName": "Product type name",
    "retries": "Retries",
    "retriesAmount": "amount of retries",
    "retrieveDate": "Retrieve date",
    "retryInterval": "Retry interval",
    "retryIntervalInSec": "Retry interval (in seconds)",
    "retryIntervalInfo": "Leave this field empty in order to retry without pause",
    "scheduleInfo": "The charging station will attempt to download and install the firmware after the time specified here",
    "scheduledAt": "Scheduled at",
    "softwareName": "Software name",
    "tableEmpty": "No firmware updates found",
    "title": "Firmware Update",
    "uploadNewUpdate": "Upload new firmware",
    "uploadNewVersion": "Upload new version"
  },
  "updatedAt": "Updated At",
  "updates": "Updates",
  "upload": "Upload",
  "user": {
    "createdBy": "Created by",
    "deleteInfo": "It will be removed from the system and will not be able to login anymore",
    "empty": "No users in this organization",
    "myAccount": "My Account",
    "preferences": {
      "email": "E-mail",
      "name": "Name",
      "organisation": "Organization",
      "timeFormat": "Time Format",
      "timezone": "Timezone",
      "title": "User Preferences",
      "unit": "Preferred Units"
    },
    "role": {
      "allAdded": "No more Roles to show. Maybe all User Roles have already been added to the whitelist.",
      "availableRoles": "Available roles",
      "empty": "No roles available in this organisation",
      "info": "Which roles should this member have within their organization (multiple can be selected)? This dialogue shows only the roles that are available in this organization (some roles might be restricted).",
      "infoEdit": "Your roles can be changed by another administrator or user administrator in your organization",
      "loading": "Loading User Roles. Please wait...",
      "myRoles": "My Roles",
      "roles": "Roles",
      "title": "Role"
    },
    "title": "User",
    "user": "User"
  },
  "users": "Users",
  "version": "Version",
  "view": "View",
  "warnings": {
    "sites": {
      "resourceConflict": "{sites} already have a different {resource}, it will be replaced by this {resource} | {sites} already has a different {resource}, it will be replaced by this {resource} | {sites} already have a different {resource}, it will be replaced by this {resource}"
    }
  },
  "whitelist": {
    "addChargers": "Add Charger To Whitelist",
    "addRfidTags": "Add RFID Tags To Whitelist",
    "addedToList": "Added To List",
    "allRfidTagsAdded": "No more RFID Tags to show. Maybe all RFID Tags have already been added to the whitelist.",
    "allSystemsAdded": "No more Chargers to show. Maybe all chargers have already been added to the whitelist.",
    "assignedChargers": "Assigned Chargers",
    "chargersEmpty": "This whitelist does not have associated chargers yet",
    "deleteInfo": "Deleting this whitelist will remove the right to charge for all the RFID tags and chargers in this list.",
    "empty": "No whitelists created yet",
    "failedChargersIds": "Some Chargers failed to be added to the whitelist:",
    "failedRemovalChargersIds": "Some Chargers failed to be removed from the whitelist:",
    "failedRemovalRfidTagsIds": "Some RFID Tags failed to be removed from the whitelist:",
    "failedRfidTagsIds": "Some RFID Tags failed to be added to the whitelist:",
    "members": "Members",
    "rfidTagsEmpty": "This whitelist does not have RFID tags yet",
    "tableEmpty": "No chargers in whitelist",
    "title": "Whitelist",
    "whitelistName": "Whitelist Name"
  },
  "whitelists": "Whitelists",
  "resources": {
    "playlist": "playlists | playlist | playlists",
    "site": "sites | site | sites",
    "tariff": "tariffs | tariff | tariffs",
    "user": "users | user | users",
    "whitelist": "whitelists | whitelist | whitelists",
    "rfidTag": "RFID tags | RFID tag | RFID tags",
    "hardwareSystem": "assets | asset | assets"
  }
}
