<template>
  <div class="flex justify-between">
    <p data-cy="system-info-field-name" class="font-medium py-3 whitespace-nowrap">
      {{ props.title }}
    </p>
    <div
      v-if="value !== undefined && value !== null"
      data-cy="system-info-field-value"
      :class="`py-3 pl-4 w-2/3 text-right font-medium ${'text-[var(--gray-700)]'}`"
    >
      <date-time-display v-if="props.isDateTime" :date="value as string" />
      <span :class="props.breakAll ? 'break-all' : ''" v-else>{{ value }} {{ unitSuffix }}</span>
    </div>
    <span v-else class="empty py-2 px-4 font-medium">--</span>
  </div>
</template>
<script setup lang="ts"> 
import { useI18n } from 'vue-i18n';
import DateTimeDisplay from '../common/time/DateTimeDisplay.vue';

const { t } = useI18n();
const props = defineProps<{
  title: string;
  value: string | Number | null;
  isDateTime?: boolean;
  breakAll?: boolean;
  unitSuffix?: string;
}>();
</script>
